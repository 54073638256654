import React from "react";
import { navigate } from "gatsby-link";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import contactHeaderImage from "../assets/images/contact-header.jpg";
import contactCard from "../assets/images/contact-card.jpg";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const PageHeader = styled.div`
  background: url(${contactHeaderImage});
  background-size: cover;
  background-position: center center;
  color: black;
  padding: 20px;
  padding-left: 0;
  min-height: 250px;
  border-bottom: solid 2px black;
  margin-bottom: 30px;
`;

export default function Contact() {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  return (
    <Layout>
      <PageHeader>
        <h1>
          <span className="animated fadeInLeft">Contact</span>
        </h1>
      </PageHeader>
      <div className="sub-page main-content">
      <p>Thanks for getting in touch.  We will respond to your query as soon as possible.</p>
        <div style={{display: 'flex'}}>
        <div>
        <form
          className="deerpark-contact-form"
          name="Deerpark Web Query"
          method="post"
          action="/thank-you/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="Deerpark Web Query" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <p>
            <label>
              Your name:
              <br />
              <input type="text" name="name" onChange={handleChange} />
            </label>
          </p>
          <p>
            <label>
              Your email:
              <br />
              <input type="email" name="email" onChange={handleChange} />
            </label>
          </p>
          <p>
            <label>
              Message:
              <br />
              <textarea name="message" onChange={handleChange} />
            </label>
          </p>
          <p>
            <button type="submit">Send</button>
          </p>
        </form>
      </div>
      <div style={{maxWidth: '400px'}}>
        <img src={contactCard} alt="" style={{maxWidth: '100%'}} />
      </div>
      </div>
      </div>
    </Layout>
  );
}
